
export const initializeHeap = () => {
    if (process.env.REACT_APP_HEAP_ID && window.heap) {
        window.heap.load(process.env.REACT_APP_HEAP_ID);
    }
}

export const identify = (email, userId) => {
    if (process.env.REACT_APP_HEAP_ID && window.heap) {
        window.heap.identify(email);
        window.heap.addUserProperties({ 
            email, 
            "Database Identifier": userId,
        })
    }
}

export const clearIdentity = () => {
    if (process.env.REACT_APP_HEAP_ID && window.heap) {
        window.heap.resetIdentity();
    }
}