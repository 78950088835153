import React from 'react';
import styled from 'styled-components';
import { Column, Row } from './defaults';
import logo from '../images/logo-white.svg';

const breakpoint = '1000px'

const FooterWrapper = styled.footer`
    display: flex;
    z-index: 1;
    flex-direction: row;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.05);
    padding: 25px 50px;
    height: 150px;

    @media (max-width: ${breakpoint}) {
        flex-direction: column;
        align-items: flex-start;
        height: initial;
        padding: 20px 30px;
        padding-top: 40px;
    }
`;

const Logo = styled.img`
    margin-bottom: 30px;

    @media (min-width: ${breakpoint}) {
        margin-top: -28px;
    }
`;

const FooterText = styled.div`
    font-family: 'Satoshi-Bold';
    font-size: 14px;
    letter-spacing: -0.013em;
    color: #051F73;

`;

const FooterColumn = styled(Column)`
    align-items: flex-start;
    
    @media (max-width: ${breakpoint}) {
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(0,0,0,.1);
        margin-bottom: 30px;
        align-self: stretch;
    }
`;

const FooterTitle = styled.div`
    font-family: 'Satoshi-Medium';
    font-size: 14px;
    letter-spacing: -0.013em;
    color: #051F73;
    flex: 1;
    margin-bottom: 23px;

    @media (max-width: ${breakpoint}) {
        margin-bottom: 30px;
    }
`;

const FooterRow = styled(Row)`
    gap: 34px;

    @media (max-width: ${breakpoint}) {
        flex-direction: column;
    }
`;

export const Footer = () => {

    return <FooterWrapper>
        <FooterColumn>
            <Logo src={logo} />
            <FooterText>© 2024, Trume Labs</FooterText>
        </FooterColumn>
        <div style={{ flex: 1 }} />
        <FooterColumn>
            <FooterTitle>QUICK LINKS</FooterTitle>
            <FooterRow>
                <FooterText>Privacy Policy</FooterText>
                <FooterText>Refund Policy</FooterText>
                <FooterText>Terms of Service</FooterText>
                <FooterText>Disclaimer</FooterText>
                <FooterText>Cookie Policy</FooterText>
                <FooterText>Shipping Policy</FooterText>
            </FooterRow>
        </FooterColumn>
        <div style={{ flex: 1 }} />
        <FooterColumn style={{ borderBottom: 'none', marginBottom: 0 }}>
            <FooterTitle>CONTACT</FooterTitle>
            <FooterText>contact@trumelabs.com</FooterText>
        </FooterColumn>
    </FooterWrapper>

}