import React, { useState } from 'react';
import styled from 'styled-components';
import ageBgEllipse from '../images/age-bg-ellipse.svg';
import bodyIcon from '../images/body.svg';
import { Column, Row } from './defaults';
import arrowUp from '../images/graph-arrow-white.svg'
import ageAd from '../images/age-ad.png'
import { BannerButton } from './banner-button';
import { AgeGraph } from './line-graph';
import { useNavigate } from 'react-router-dom';
import { FinalAge } from './final-age';

const Banner = styled(Column)`
    grid-area: agebanner;
    border-radius: 32px;
    background-color: rgba(10, 19, 48, 1);
    overflow: hidden;
    position: relative;

    @media (max-width: 762px) {
        border-radius: 20px;
    }
`;

const AgeBgEllipse = styled.img`
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 1;
`;

const BodyIcon = styled.img`
    margin-bottom: 38px;

    @media (max-width: 762px) {
        margin-bottom: 10px;
    }
`;

const Contents = styled(Column)`
    align-items: center;
    padding: 40px;
    z-index: 10;
    position: relative;
    flex: 1;

    @media (max-width: 762px) {
        padding: 20px;
    }

    
`;

const TopText = styled.div`
    text-align: center;
    color: white;
    font-size: 28px;
    line-height: 32px;
    font-family: 'Satoshi-Medium';

    b {
        color: rgba(30, 220, 255, 1)
    }

    @media (max-width: 762px) {
        font-size: 20px;
        line-height: 24px;
    }
`;

const TopTextComplete = ({ age, older }) => <>
        <TopText>Your TruMe Age is</TopText>
        <TopText><b>{age} {age === 1 ? 'year' : 'years'} {older ? 'older' : 'younger'}</b> than</TopText>
        <TopText>your chronological age.</TopText>
    </>

const TopTextAd = () => <>
        <TopText>Discover wellness and</TopText>
        <TopText>decide longevity with a</TopText>
        <TopText>TruMe age test.</TopText>
    </>


const AgeImageAdWrapper = styled(Column)`
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 20;
`


const AgeAdImg = styled.img`
    width: 50%;
`;

const AgeImageAd = () => {
    return <AgeImageAdWrapper>
        <Row style={{ marginTop: 40, alignItems: 'center', justifyContent: 'center' }}> 
            <AgeAdImg src={ageAd} />
        </Row>
    </AgeImageAdWrapper>
}


const AgeBannerAd = () => {
    return <>
        <Contents>
            <BodyIcon src={bodyIcon} />
            <TopTextAd />
            <div style={{ flex: 1 }} />
            <BannerButton 
            style={{zIndex: 35}}
            icon={arrowUp}
            onClick={() => window.open('https://trumelabs.com/biological-age-test/','_blank')}
            outerText={'Understand your aging'}
            innerText='Order Test' />
            <AgeImageAd />
        </Contents>
    </>
}

const GraphComplete = ({ ageData }) => {
    
    return <div style={{ width: '90%', height: 'min-content'}}>
        <AgeGraph />
    </div>
}

const FulfilledAgeWrapper = styled(Column)`
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;


    @media (max-width: 600px) {
        transform: scale(0.7) !important;
        z-index: 10;
    }
`;

export const AgeBannerFulfilled = ({ ageData }) => {
    const [selectedTab, setSelectedTab] = useState('latest');
    const lastAgeData = ageData[0];
    const difference = Math.abs(lastAgeData?.biological_age - lastAgeData?.chronological_age).toFixed(1);
    const navigate = useNavigate();
    
    return <>
        <Contents>
            <Toggle selected={selectedTab} setSelected={setSelectedTab} />
            {selectedTab === 'latest' && <TopTextComplete age={difference} older={lastAgeData?.biological_age > lastAgeData?.chronological_age} />}
            {selectedTab === 'all_time' && <GraphComplete ageData={ageData}/>}
            <div style={{ flex: 1 }} />
            <BannerButton 
            icon={arrowUp}
            onClick={() => navigate('/dashboard/age')}
            outerText={`Chronological age: ${lastAgeData?.chronological_age.toFixed(1)}`}
            innerTextMobile='View Details'
            innerText='View Details' />
        </Contents>
        {selectedTab === 'latest' && 
        <FulfilledAgeWrapper>
            <FinalAge age={lastAgeData?.biological_age} />
        </FulfilledAgeWrapper>}
    </>
}


const ToggleWrapper = styled(Row)`
    align-self: center;
    margin-top: 0px;
    margin-bottom: 30px;
`;

const ToggleButton = styled.div`
    color: white;
    background: ${props => props.selected ? 'rgba(255,255,255,0.2)' : 'transparent'};
    cursor: ${props => props.selected ? 'initial' : 'pointer'};
    padding: 12px 18px;
    border-radius: 40px;
    font-size: 20px;
`;

const Toggle = ({ selected, setSelected }) => {
    return <ToggleWrapper>
        <ToggleButton selected={selected === 'latest'} onClick={() => setSelected('latest')}>Latest</ToggleButton>
        <ToggleButton selected={selected === 'all_time'} onClick={() => setSelected('all_time')}>All Time</ToggleButton>
    </ToggleWrapper>
}

export const AgeBanner = ({ ageData }) => {
    return <Banner>
        {ageData?.length ? <AgeBannerFulfilled ageData={ageData} /> : <AgeBannerAd />}
        <AgeBgEllipse src={ageBgEllipse} />
    </Banner>
}