import { createGlobalStyle } from 'styled-components';

const cdnPath = 'https://storage.googleapis.com/static-files-trumemain/';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Satoshi-Medium';
    src: url('${cdnPath}Satoshi-Medium.woff2') format('woff2'),
         url('${cdnPath}Satoshi-Medium.woff') format('woff'),
         url('${cdnPath}Satoshi-Medium.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Satoshi-Regular';
    src: url('${cdnPath}Satoshi-Regular.woff2') format('woff2'),
         url('${cdnPath}Satoshi-Regular.woff') format('woff'),
         url('${cdnPath}Satoshi-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Satoshi-Bold';
    src: url('${cdnPath}Satoshi-Bold.woff2') format('woff2'),
         url('${cdnPath}Satoshi-Bold.woff') format('woff'),
         url('${cdnPath}Satoshi-Bold.ttf') format('truetype');
  }
  body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Satoshi-Bold', sans-serif;
  }
  a, button {
    font-family: 'Satoshi-Medium', sans-serif;
  }
`;

export default GlobalStyle;