import React from 'react';
import styled from 'styled-components';
import { Row } from './defaults';
import logoDark from '../images/logo-dark.svg';
import logoWhite from '../images/logo-white.svg';
import { useNavigate } from 'react-router-dom';

const Logo = styled.img`
    height: 40px;
    cursor: pointer;

    @media (max-width: 768px) {
        height: 43px;
    }
`;

const Separator = styled.div`
    height: 30px;
    width: 1px;
    background: ${props => props.dark ? '#FFFFFF' : '#E0E0E0'};
    margin-left: 14px;
    margin-right: 21px;

    @media (max-width: 768px) {
        height: 33px;
        margin-left: 9px;
        margin-right: 17px;
        width: 1px;
    }
`;

const Text = styled.div`
    font-size: 18px;
    color: ${props => props.dark ? 'white' : '#051F73'};

    @media (max-width: 768px) {
        font-size: 16px;
    }
`;      

const Wrapper = styled(Row)`
    border-bottom: ${props => props.dark ? '1px solid #FFFFFF' : '1px solid #E0E0E0'};
    align-items: center;
    padding: 16px;
    background: ${props => props.dark ? '#4F59F0' : 'white'};

    @media (max-width: 768px) {
        padding-left: 15px;
        padding-right: 15px;
    }
`;

export const KitHeader = ({ dark }) => {

    const navigate = useNavigate()

    return <Wrapper dark={dark}>
        <Logo onClick={() => navigate('/kit/number')} src={dark ? logoDark : logoWhite}/>
        <Separator dark={dark} />
        <Text dark={dark}>Kit Registration</Text>
    </Wrapper>

}