export const DnaIcon = ({ fill='#4F59F0' }) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="24.000000pt" height="24.000000pt" viewBox="0 0 24.000000 24.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
      fill={fill} stroke="none">
      <path d="M30 140 c0 -27 5 -50 10 -50 6 0 10 16 10 35 0 19 5 35 10 35 6 0 10
      -12 10 -27 0 -21 3 -24 10 -13 5 8 12 11 16 7 9 -9 -23 -45 -47 -52 -11 -4
      -19 -11 -19 -16 0 -17 28 -9 59 17 l31 26 31 -26 c31 -27 59 -34 59 -16 0 6
      -5 10 -10 10 -6 0 -40 27 -75 60 -81 75 -95 77 -95 10z"/>
      <path d="M159 172 c-21 -18 -22 -20 -6 -43 l17 -23 0 27 c0 15 5 27 10 27 6 0
      10 -16 10 -35 0 -19 5 -35 10 -35 6 0 10 23 10 50 0 56 -12 63 -51 32z"/>
      </g>
    </svg>
  )
}