import { getAuth, signInWithCustomToken, signOut } from "firebase/auth"
import { useEffect } from "react"
import { NotificationManager } from 'react-notifications';
import { useNavigate } from "react-router-dom";

export const Impersonate = () => {

    const navigate = useNavigate();

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    const runImpersonation = async () => {
        try {
            await signOut(getAuth());
            await signInWithCustomToken(getAuth(), token)
        } catch (err) {
            NotificationManager.error('An Error Happened')
        }
        navigate('/sign-in');

    }

    useEffect(() => {
        if (token) runImpersonation()
    }, [token])

    return <div style={{ height: '100vh' }}>Loading</div>
    

}