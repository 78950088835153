import axios from 'axios';
import { getAuth } from 'firebase/auth';

const baseURL = process.env.REACT_APP_API_URL ?
process.env.REACT_APP_API_URL :
process.env.REACT_APP_API_HOST ? 
`http://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`:
`http://localhost:${process.env.REACT_APP_API_PORT}`



const api = axios.create({ baseURL })

const streamApi = axios.create({ baseURL })

const passwordApi = axios.create({ baseURL })


api.interceptors.request.use(
    async (config) => {
        const auth = getAuth()
        const user = auth.currentUser;
        if (user) {
            const token = await user.getIdToken(); 
            config.headers['Content-Type'] = 'application/json'
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

streamApi.interceptors.request.use(
    async (config) => {
        const auth = getAuth()
        const user = auth.currentUser;
        if (user) {
            const token = await user.getIdToken(); 
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)

passwordApi.interceptors.request.use(
    async (config) => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token')
        config.headers['Content-Type'] = 'application/json'
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default api;

export {
    streamApi,
    passwordApi
};