/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Row, FlexRow } from '../components/defaults';
import { fetchUserData } from '../redux/reducer/user';
import loadingIcon from '../images/grid.svg';
import { Arrow } from '../images/arrow';
import welcomeCurve from '../images/welcome-curve.svg';
import pdf from '../images/pdf.svg';
import useAuth from '../utils/useAuth';
import { FolateVariant } from '../components/folate-panel'
import { BannerButton } from '../components/banner-button';
import { LoadingMask } from '../components/loading-mask';
import { downloadPDF } from '../utils/downloader';
import api from '../utils/api';

export const RED = '#FF374F';
export const YELLOW = '#FFCE1E';
export const GREEN = '#40F93C';

const Wrapper = styled(Column)`
    background: #FDFDFF;
`;

const LoadingScreenWrapper = styled(Column)`
    align-items: center;
    justify-content: center;
    height: 80vh;
`;

const LoadingIcon = styled.img`
    height: 40px;
`;

const GeneticDashboardWrapper = styled.div`
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 1200px;
    align-self: center;
`

const DataWrapper = styled.div`
    background-image: url(${welcomeCurve});
    background-repeat: no-repeat;
    background-size: cover;
    margin: 10px 0px;
    box-shadow: rgba(79, 89, 240, 0.02) 0px 20px 24px 0px;
    border: 1px solid rgb(226, 226, 255);
    border-radius: 16px;
    padding: 10px;
`

const DataHeader = styled.div`
//  background: #4F59F0;
 border-radius: 16px 16px 0 0;
 padding: 20px;
`

const Title = styled.div`
  color: white;
  font-size: 30px;
  font-family: 'Satoshi-Bold';
  width: 100%;
`;

const Subtitle = styled.div`
  color: white;
  font-size: 18px;
  font-family: 'Satoshi-Medium';
  width: 100%;
`

const OverviewTitle = styled.div`
  font-size: 18px;
  font-family: 'Satoshi-Medium';
  line-height: 38px;
  height: 100px;
  color: #051F73;
  flex: 1;
`

const Overview = styled.div`
  font-size: 16px;
  font-family: 'Satoshi-Medium';
  line-height: 38px;
  color: #000;
  flex: 1;
  text-align: justify;
  
  @media (max-width: 768px) {
    width: 100%;
    position: relative;
    line-height: 25px;
    max-height: ${(props) => props.expand ? 'none' : '6em'};
    display: ${(props) => props.expand ? 'flex' : '-webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical'};
    overflow: ${(props) => props.expand ? 'visible' : 'hidden'};
    text-overflow: ellipsis;
  }
`

const DataContent = styled.div`
    display: flex;
    padding: 20px;
    gap: 50px;
    height: fit-content;
    @media (max-width: 768px) {
        flex-direction: column;
        padding: 5px;
    }
`

const GeneticCards = styled.div`
    display: flex;
    // grid-template-columns: 0.1fr 0.1fr 0.1fr;
    // grid-template-rows: 0.1fr 0.1fr 0.1fr;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    height: 50%;
    width: 50%;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
`

const GeneticInstructions = styled.div`
    box-shadow: rgba(79, 89, 240, 0.02) 0px 20px 24px 0px;
    // border: 1px solid rgb(226, 226, 255);
    padding: 20px;
    border-radius: 16px;
    background: white;
    // width: 50%;
    width: 50%;
    @media (max-width: 768px) {
        width: auto;
        display: none;
    }
`

const GeneticOverview = styled.div`
    // width: 100%;
    margin: 0px 20px 10px 20px;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 16px;
    @media (max-width: 768px) {
       margin: 5px;
       margin-top: 10px;
    }
`

const ExpandButton = styled.button`
    display: none;
    @media (max-width: 768px) {
        display: block;
        padding: 10px;
        margin-top: 20px;
        color: #051F73;
        text-align: center;
        font-size: 18px;
        background: #fff;
        padding: 10px 15px;
        border-radius: 8px;
        border: 1px solid rgba(27, 31, 35, 0.15);;
        cursor: pointer;
        width: 100%;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
        background-color: #FAFBFC;
    }
`

const Sidebar = styled(Column)`
  background: white;
  border-radius: 16px;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  padding: 27px;
  box-sizing: border-box;
  gap: 24px;
  width: 100%;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Supplement = styled(Row)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #DBDBDB;
    border-radius: 16px;
    padding: 15px 30px;
    color: #051F73;
    box-sizing: border-box;
    color: #051F73;
    font-size: 18px;

    @media (max-width: 768px) {
        padding: 10px;
        font-size: 14px;
        flex-direction: column;
        gap: 10px;
    }
`;

const SupplementLeft = styled.div`
  display: flex;
  gap: 5px;
  height: 100%;
  align-items: center;
   & > img {
        height: 50px;
        width: 50px;
        background: #F1F3F7;
        padding: 12px;
        border-radius: 10px;
        box-sizing: border-box;
        margin-right: 24px;
        overflow: hidden;
    }
   @media (max-width: 768px) {
        width: 100%;
        & > img {
            margin-right: 5px;
        }
    }  
`

const SupplementRight = styled.div`
  display: flex;
  margin-left: auto;
  height: 100%;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const OrderNowButton = styled.button`
    display: flex;
    gap: 10px;
    color: #000;
    font-size: 18px;
    background: #fff;
    padding: 10px 15px;
    border-radius: 8px;
    border: 1px solid rgba(27, 31, 35, 0.15);;
    cursor: pointer;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    background-color: #FAFBFC;
    > div svg {
        stroke: #000;
    }
    &:hover {
        background: #051F73;
        color: #fff;
        & > div svg {
            stroke: #fff;
        }
    }
`

const ColorBox = styled.div`
    height: 30px;
    width: 30px;
    border-radius: 5px;
    margin-bottom: 24px;
`;

const InstructionsDescription = styled.div`
    font-family: 'Satoshi-Regular';
    font-size: 1em;
    color: #051F73;

    @media (max-width: 768px) {
        font-size: 0.9em;
    }

    & b {
        font-family: 'Satoshi-Bold';
    }
`;
  
const MutationCount = styled.div`
    font-family: 'Satoshi-Bold';
    font-size: 1em;
    color: #051F73;
  
    @media (max-width: 768px) {
      font-size: 0.8em;
    }
`;
  
const MutationCountFooter = styled.div`
    font-family: 'Satoshi-Regular';
    font-size: 1em;
    color: #051F73;
  
    @media (max-width: 768px) {
      font-size: 0.8em;
    }
`;

const Icon = styled.img``

const LoadingScreen = () => {
    return <LoadingScreenWrapper>
        <LoadingIcon src={loadingIcon} />
    </LoadingScreenWrapper>
}

const instructionsCopy = {
    green: {
      background: GREEN,
      mutations: 0,
      functioning: '100%',
      boldDescription: 'No',
      description: 'risk variants present'
    },
    yellow: {
      background: YELLOW,
      mutations: 1,
      functioning: '~40',
      boldDescription: 'One',
      description: 'risk variant present'
    },
    red: {
      background: RED,
      mutations: 2,
      functioning: '0 - 10',
      boldDescription: 'Two',
      description: 'risk variants present'
    },
}
  

  
const Instructions = ({ color }) => {
    const { background, boldDescription, mutations, functioning, description } = instructionsCopy[color];
    
    return <Column style={{ padding: '10px' }}>
      <ColorBox style={{ background }} />
      <InstructionsDescription><b>{boldDescription}</b> {description}</InstructionsDescription>
      <Row>
        <Column style={{ flex: 1 }}>
          <MutationCount>{mutations}</MutationCount>
          <MutationCountFooter>{mutations === 1 ? 'Mutation' : 'Mutations'}</MutationCountFooter>
        </Column>
        <Column>
          <MutationCount>{functioning}</MutationCount>
          <MutationCountFooter>% Functioning</MutationCountFooter>
        </Column>
      </Row>
    </Column>
}
  
const Border = styled.div`
    width: 100%;
    height: 1px;
    background: #C0C1DD;
`;

const GeneticsData = ({ geneticData }) => {
    const [expand, setExpand] = useState(false)

    return <DataWrapper>
        <DataHeader>
            <Title>Unlock the Secrets of Your DNA - Kit #{geneticData['barcode']}</Title>
        </DataHeader>
        
        <DataContent>
            <GeneticCards>
                <FolateVariant name='COMT'/>
                <FolateVariant name='AHCY'/>
                <FolateVariant name='MTRR'/>
                <FolateVariant name='MTR'/>
                <FolateVariant name='MTHFR'/>
            </GeneticCards>
            <GeneticInstructions>
                <Instructions color='green' />
                <Border />
                <Instructions color='yellow' />
                <Border />
                <Instructions color='red' />
            </GeneticInstructions>
        </DataContent>
        <GeneticOverview>
            <OverviewTitle>Overview:</OverviewTitle>
            <Overview expand={expand}>{geneticData['recommended_set']['overview']}</Overview>
            <ExpandButton onClick={()=> setExpand(prevValue => !prevValue)}>
                {expand ? 'Read less' : 'Read more'}
            </ExpandButton>
        </GeneticOverview>
    </DataWrapper>
}

const SupplementsData = ({ geneticData }) => {
    return <DataWrapper>
        <DataHeader>
            <Title>Recommended just for you.</Title>
            <Subtitle>{geneticData['recommended_set']['subtitle']}</Subtitle>
        </DataHeader>
        <DataContent>
           <RecommendationSiderBar supplements={geneticData['recommended_set']['supplements']}/>
        </DataContent>
    </DataWrapper>
}

const RecommendationSiderBar = ({ supplements }) => {
    return (
        <Sidebar>
            {supplements.map(({name, url, icon}) => {
                return (
                    <Supplement>
                        <SupplementLeft>
                            {icon && <Icon alt={name} src={require(`../images/supplement-icons/${icon}.svg`)} />}
                            {name}
                        </SupplementLeft>
                        <SupplementRight>
                            {url ? <OrderNowButton onClick={() => window.open(url, '_blank')}>
                                Order Now
                                <Arrow />
                            </OrderNowButton> : 'Coming soon'}
                        </SupplementRight>
                    </Supplement>
                )
            })}
        </Sidebar>
    )
}

const FolateText = styled.div`
    font-family: 'Satoshi-Medium';
    color: white;
    font-size: 28px;
    margin-bottom: 3px;
    width: 80%;

    span {
        background: -webkit-linear-gradient(102.69deg, #F9BF29 3.41%, #FFF6DF 98.2%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @media (max-width: 762px) {
        font-size: 22px;  
        width: 90%;
        margin-bottom: 20px;
    }

    ${props => props.gold ? `
        background: -webkit-linear-gradient(102.69deg, #F9BF29 3.41%, #FFF6DF 98.2%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    `: ''}
`;

const DownloadWrapper = styled.div`
    position: relative;
    flex: 1;
    padding: 30px;
    margin: 10px 0px 0px 0px;
    background-image: url(${welcomeCurve});
    border-radius: 16px;

    @media (max-width: 768px) {
        padding: 15px 15px;
    }
`;

const DownloadReport = ({ geneticData }) => {
    const [ loading, setLoading ] = useState(false)

    const openPdf = async () => {
        setLoading(true)
        try {
            const res = await api.get(`/reports?barcode=${geneticData.barcode}`);
            if (!res.data.url) NotificationManager.success('Your report is being processed. Please check back later.')
            else await downloadPDF(res.data.url);
        } catch (err) {
            
        }
        setLoading(false);
    } 

    return (
        <DownloadWrapper>
            {loading && <LoadingMask />}
            <FlexRow style={{ alignContent: 'center' }}>
                <Column style={{ flex: 1}}> 
                    <FolateText>Your folate genotype has
                        <span> 10 variations that impact </span> 
                    efficient folate metabolism.</FolateText>
                </Column>
                <BannerButton 
                    outerText='View your full genetics report!'
                    innerTextMobile='Download Report'
                    innerText='Download'
                    disabled={loading}
                    onClick={() => openPdf(geneticData.barcode)}
                    icon={pdf}
                />
            </FlexRow>
        </DownloadWrapper>
    )
}


export const GeneticDashboard = () => {

    const dispatch = useDispatch();
    const [ forceLoading, setForceLoading ] = useState(true)
    const { currentUser, isLoading } = useAuth()
    const { userData, loading } = useSelector((s) => s.user)
    const dataAvailable = !loading && Object.keys(userData).length > 0

    useEffect(() => {
        if (currentUser && !dataAvailable) {
            dispatch(fetchUserData())
        };
        if (dataAvailable) { setForceLoading(false) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, dataAvailable])

    const geneticData = useSelector((state) => state.user.userData.genetic_kit);

    if (!geneticData?.data) return <div />

    return <Wrapper>
        {forceLoading || isLoading ? <LoadingScreen /> : 
        <GeneticDashboardWrapper>
            <DownloadReport geneticData={geneticData} />
            <GeneticsData geneticData={geneticData} />
            { geneticData?.recommended_set &&  <SupplementsData geneticData={geneticData} /> }
        </GeneticDashboardWrapper>}
    </Wrapper>
}