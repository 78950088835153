import React from 'react';
import styled from 'styled-components';
import { Row } from './defaults';


const ProgressBarWrapper = styled(Row)`
  height: 16px;
  background: none;
  border-radius: 3px;
  overflow: hidden;
  min-width: 300px;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  position: relative;

  @media (max-width: 500px) {
    min-width: 230px;
    width: 100%;
  }
`;

const ProgressSection = styled.div`
  position: relative;
  height: 100%;
  flex: 1;
  background: ${(props) =>
    !props.filled ? 'rgba(79, 89, 240, 0.4)' : 'none'};
  transition: background 0.3s ease;
  border-radius: 3px;
  overflow: hidden;

  /* Add the pseudo-element for filling */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${(props) =>
      props.isLastFilled ? '50%' : props.filled ? '100%' : '0'};
    background: linear-gradient(90deg, #4F59F0 0%, #1EDCFF 100%);
    transition: width 0.3s ease;
  }

   &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: ${(props) => (props.isLastFilled ? '50%' : '0')};
    background: rgba(79, 89, 240, 0.4);
    transition: width 0.3s ease;
  }
`;

const ProgressBarDot = styled.div`
    position: absolute;
    top: 50%;
    left: ${(props) => {
        const sectionWidthPercentage = 100 / props.totalSections; // Width of each section
        const middleOfSection = (props.currentSection - 0.6) * sectionWidthPercentage; // Middle point of section
        return `calc(${middleOfSection}% + ${(props.currentSection - 1) * 4}px)`; // Adjust for gaps
    }};
   transform: translate(-50%, -50%);
   width: 4px;
   height: 16px;
   background-color: white;
   border-radius: 10px;
   border: 3px solid;
   box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
`;

export const StatusProgressBar = ({ totalSections = 4, step = 1 }) => {
    return (
      <ProgressBarWrapper>
        {[...Array(totalSections)].map((_, index) => (
          <>
            <ProgressSection
                key={index}
                width={100 / totalSections}
                filled={index < step}
                isLastFilled={index === step - 1}
            />
            <ProgressBarDot currentSection={step} totalSections={totalSections} />
          </>
        ))}
      </ProgressBarWrapper>
    );
  };