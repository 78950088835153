import styled from "styled-components"
import { RegistrationForm } from "./template";
import { personalDetail } from "../../constants/registration/personal-detail";
import { clearRegistrationData } from "../../utils/registration";
import api from "../../utils/api";
import { useNavigate } from "react-router-dom";
import useAuth from "../../utils/useAuth";
import { useSelector } from "react-redux";
import { getAuth, signInWithCredential, signInWithEmailAndPassword } from "firebase/auth";

const Fields = styled.div`
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    margin-top: 30px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-template-areas: 
        "date_of_birth"
        "biological_sex"
        "gender"
        "country"
        "ethnicity"
`;

const requiredFields = [
    'date_of_birth',
    'biological_sex',
    'gender',
    'country',
    'ethnicity',
]




export const SubmitSignUp = () => {

    const navigate = useNavigate();
    const values = useSelector((state) => state.registration);
    const auth = getAuth()

    const submit = async () => {
        try {
            const data = clearRegistrationData(values);
            const link = '/auth/clean-register'
            await api.post(link, data);
            await signInWithEmailAndPassword(auth, data.email, data.password);
            navigate('/dashboard?first_time=true');
        } catch (err) {
            throw new Error(err.message);
        }
    }
    
    return <RegistrationForm02
    title='Can you confirm the following information?'
    progress={75}
    cta='Create account'
    submitAction={submit}
    />

}


export const RegistrationForm02 = ({
    title='Please provide the following information:',
    progress=32,
    cta='Next',
    submitAction
}) => {

    return <RegistrationForm
        subtitle='Personal Details'
        title={title}
        Fields={Fields}
        fields={Object.values(personalDetail)}
        progress={progress}
        buttonCta={cta}
        nextRoute={!submitAction ? '/registration/3' : ''}
        requiredFields={requiredFields}
        submitAction={submitAction}
    />
    
}