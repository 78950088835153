import React from 'react';
import { LinkTertiary, Container } from '../components/defaults';
import styled from 'styled-components';
import mainbg from '../images/main-bg.svg';
import sphere from '../images/sphere.svg';
import topCurve from '../images/top-curve.svg';
import backlinkArrow from '../images/arrow-left.svg';
import { AuthHeader } from '../components/auth-header';
import { 
    Contents, 
    FormBox, 
    FormContents, 
    MainBg, 
    Sphere, 
    ThisTitle, 
    TopCurve 
} from '../components/auth-defaults';

const BacklinkArrow = styled.img`
    height: 15px;
    margin-right: 10px;
`;

const BackLink = styled(LinkTertiary)`
    margin-bottom: 40px;
    align-self: flex-start;
    margin-left: 0px;

    @media (max-width: 762px) {
        margin-bottom: 20px;
    }
`;

const Message = styled.div`
    background: #F4F3FE;
    color: #051F73;
    font-family: 'Satoshi-Medium';
    font-size: 18px;
    padding: 16px 24px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: -10px;
`;

export const ResetPassword = () => {

    return <Container style={{ background: '#4F59F0'}}>
        <AuthHeader dark={true} />
        <Contents> 
                <FormBox>
                    <FormContents>
                        <BackLink to={'/sign-in'}>
                            <BacklinkArrow src={backlinkArrow} />
                            Login
                        </BackLink>
                        <ThisTitle>Reset your password</ThisTitle>
                        <Message>For your security, please check your email for a message with instructions to reset your password.</Message>
                    </FormContents>
                    <TopCurve src={topCurve}/>
                </FormBox>
            <MainBg src={mainbg}/>
            <Sphere src={sphere}/> 
        </Contents>
        
    </Container>    
}