import styled from "styled-components"
import { Column, Row } from "./defaults"
import Select from 'react-dropdown-select';
import eyeIcon from '../images/eye.svg';
import { useState } from "react";
import PhoneInput from "react-phone-input-2";

const Label = styled.label`
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.007em;
    color: ${props => props.error ? 'red' : '#5B637D' } ;
`;

const Error = styled.div`
    color: red;
    font-size: 12px;
    height: 15px;
    margin-top: 5px;
`;

const Wrapper = styled(Column)`
    gap: 7px;
    min-width: 100px;

`;

const InputBox = styled(Column)`
    height: 56px;
    flex: 1;
    background: #F9F9F9;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    overflow: ${props => props.isDropdown ? 'initial' : 'hidden'};
    box-sizing: border-box;

    & > div {
        height: 100%;
    }

    @media (max-width: 768px) {
        height: 50px;

        & > input {
            font-size: 16px;
            padding: 10px;
            line-height: 16px;
        }
    }
`;

const Input = styled.input`
    font-family: 'Satoshi-Medium';
    font-size: 16px;
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #051F73;
    background: transparent;
    outline: none;
    border: none;
    flex: 1;
`;

const EyeButton = styled.img`
    height: 30px;
    width: 30px;
    padding-right: 10px;
    cursor: pointer;
    opacity: ${ props => props.visible ? 1 : 0.5};
`;

const Suffix = styled.div`
    padding-left: 15px;
    font-size: 16px;
    color: rgba(0,0,0,.5);
`;

const EnhancedSelect = styled(Select)`

    flex: 1;
    color: #051F73;
    font-weight: 300;


    .react-dropdown-select-input {
        font-size: 16px !important;
        border: none !important;
        outline: none !important;
        color: #051F73;
        padding-left: 3px;
    }

    &.react-dropdown-select {
        outline: none !important;
        border: none !important;
        border-radius: 8px;
        height: 100%;
        padding-right: 15px;
        padding-left: 15px;
        font-weight: 300 !important;
    }
`;

const NoDropdownSelectItem = styled.div`
    padding-top: 13px;
    padding-bottom: 14px;
    ${props => props.noPadding ? '' : `padding-left: 22px;
    padding-right: 22px;` }
    font-size: 16px;
    color: #4F59F0;
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
    border: ${ props => props.selected ? 
        '1px solid #4F59F0':
        '1px solid #DBDBDB'};
    background: ${ props => props.selected ? 
        'rgba(79, 89, 240, 0.05)':
        'white'};
    cursor: ${props => props.selected ? 'initial' : 'pointer'};

    @media (max-width: 768px) {
        font-size: 13px;
        padding: 10px;
    }
`;

const NoDropdownSelectWrapper = styled.div`
    display: grid;
    gap: 15px;
    margin-top: 8px;
    flex: 1;

    @media (max-width: 768px) {
        gap: 5px;
    }
`;

const NoDropdownSelect = ({
    options,
    value,
    onChange,
    noPadding,
    optionsGridStyle = {
        gridAutoFlow: 'column'
    }
}) => {

    return <NoDropdownSelectWrapper style={{...optionsGridStyle }}>
        {options.map((option) => <NoDropdownSelectItem 
        noPadding={noPadding}
        selected={value?.value === option.value}
        onClick={() => onChange(option)}>
            {option.label}
        </NoDropdownSelectItem>)}
    </NoDropdownSelectWrapper>

}

const PhoneInputWrapper = (props) => {

    const thisError = props.errors[props.id]

    return <Column style={{ flex: 1 }}>
    <InputBox style={{ alignItems: 'center', justifyContent: 'stretch'}}>
        <PhoneInput 
        {...props}
        style={{ padding: 20, height: 56, boxSizing: 'border-box', border: 'none' }}
        preferredCountries={['us']}
        country='us'
        value={props.value}
        containerClass="phoneInput"
        inputClass="phoneInputInput"
        buttonClass="phoneInputButton"
        onChange={props.onChange} />
    </InputBox>
    {thisError && <Error>{thisError}</Error>}
    </Column>


}

export const PasswordInputWrapper = (props) => {

    const [visible, setVisible] = useState(false);

    const thisError = props.errors[props.id]

    return <Column style={{ flex: 1 }}>
        <Row style={{ flex: 1, alignItems: 'center', boxSizing: 'border-box' }}>
            <InputBox style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                <Input value={props.value} onChange={props.onChange} {...props} type={visible ? 'text' : 'password'} />
                <EyeButton visible={visible} onClick={() => setVisible((v) => !v)} src={eyeIcon} />
            </InputBox>
        </Row>
        {thisError && <Error>{thisError}</Error>}
    </Column>
}

const DefaultInputWrapper = (props) => {

    let {
        options, 
        type, 
        noDropdown, 
        optionsGridStyle,
        suffix,
        index,
        placeholder,
        id,
        onChange,
        value,
        errors
    } = props;

    placeholder = (placeholder?.length && placeholder[index]) ? placeholder[index] : placeholder;
    props = { id, placeholder, options, value, onChange, type, ...props}
    const thisError = errors[id]

    return <>
        <Row style={{ flex: 1, alignItems: 'center', boxSizing: 'border-box' }}>
            {(options?.length && noDropdown) && <NoDropdownSelect  optionsGridStyle={optionsGridStyle} {...props} />}
            {!noDropdown && <InputBox isDropdown={options?.length}>
                {!options?.length ? <Input  {...props} placeholder={placeholder} /> :
                <EnhancedSelect values={Array.isArray(value) ? value : [value]} onChange={(e) => onChange(e[0])} {...props} />}  
            </InputBox>}
            {suffix && <Suffix>{suffix}</Suffix>}
        </Row>
        {thisError && <Error>{thisError}</Error>}
    </>

}


export const MainInput = (props) => {

    const {
        label, 
        gridArea,
        subIds,
        value,
        nestedQuestion,
        errors,
        onChange,
        suffix,
        id
    } = props;

    const InputComponent = (props.type === 'password') ? PasswordInputWrapper
    : (props.type === 'phone') ? PhoneInputWrapper
    : DefaultInputWrapper;

    return <Wrapper style={{ gridArea }}>
        <Label>{label}</Label>
        
        {subIds ? subIds.map((id, i) => <InputComponent 
        value={value[id]} 
        errors={errors}
        onChange={onChange(id)}
        key={i}
        id={id} 
        suffix={suffix[i]}
        index={i} />) : 
        <InputComponent 
        {...props}
        onChange={onChange(id)}
        value={nestedQuestion?.id ? value[id] : value} />}
        

        {nestedQuestion && 
        nestedQuestion?.condition === value[id]?.value && 
        <Column style={{ marginTop: 15, gap: 10, alignSelf: "stretch" }}>
            <Label>{nestedQuestion.label}</Label>
            <InputComponent 
            onChange={onChange(nestedQuestion.id)}
            value={value[nestedQuestion?.id]}
            errors={errors}
            {...nestedQuestion} />
        </Column>}
    </Wrapper>
}

