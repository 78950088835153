import React, { useState } from 'react';
import profile from '../images/profile.svg';
import styled from 'styled-components';
import { signOut, getAuth } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { clear } from '../redux/reducer/user';
import { useNavigate } from 'react-router-dom';
import { Column } from './defaults';

const Wrapper = styled.div`
    position: relative;
    z-index: 99999;
`;

const ProfileIcon = styled.img`
    height: 30px;
    width: 30px;
    cursor: pointer;
`;

const Ribbon = styled(Column)`
    position: absolute;
    right: 0px;
    top: 35px;
    border-radius: 20px;
    background: white;
    box-shadow: 1px 1px 5px rgba(0,0,0,.5);
    z-index: 100;
    padding: 20px;
    gap: 10px;
    width: 140px;
`;

const Mask = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 99;
    background: rgba(0,0,0,.3);
`;

const Item = styled.div`
    white-space: nowrap;
    cursor: pointer;
    
`;

export const UserRibbon = () => {

    const [ribbonVisible, setRibbonVisible] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onClickSignOut = async () => {
        await signOut(getAuth())
        dispatch(clear());
    }

    const onClickProfile = async () => {
        navigate('/dashboard/profile');
        setRibbonVisible(false)
    }

    return <Wrapper>
        <ProfileIcon onClick={() => setRibbonVisible(true)} src={profile} />
        {ribbonVisible && <Mask onClick={() => setRibbonVisible(false)} />}
        {ribbonVisible && <Ribbon>
            <Item onClick={onClickProfile}>Profile</Item>
            <Item onClick={onClickSignOut}>Sign Out</Item>
        </Ribbon>}
    </Wrapper>

}