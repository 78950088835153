

export const clearRegistrationData = (values) => {
    return Object.entries(values).reduce((prev, [key, value]) => {
        if (Array.isArray(value)) return {...prev, [key]: value[0].value}
        if (value?.value) return {...prev, [key]: value?.value}
        return {...prev, [key]: value}
    }, {})
}

