import styled from "styled-components"
import { Button, Column } from "../../components/defaults"
import { 
    RegistrationSectionTitle, 
    RegistrationProgressBar, 
    RegistrationInput
} from "../../components/registration-components";
import { KitHeader } from "../../components/kit-header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateRegistrationValue } from "../../redux/reducer/registration";

const Wrapper = styled(Column)`
    max-width: 420px;
    padding: 50px;

    @media screen and (max-width: 768px) {
        padding: 20px;
    }
`;

const Spacer = styled.div`
    height: ${props => props.height}px;
`;


const ErrorComponent = styled.div`
    margin-top: 10px;
    color: red;
    font-size: 12px;
`;

export const RegistrationForm = ({
    title,
    progress,
    subtitle,
    buttonCta = 'Next',
    Fields,
    fields,
    nextRoute,
    requiredFields = [],
    validations,
    submitAction,
    templateAreas,
    subtitleHtml
}) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [fieldError, setFieldError] = useState({});
    const [otherError, setOtherError] = useState(null);
    const [searchParams] = useSearchParams();
    const barcode = searchParams.get('kit');
    const dispatch = useDispatch();

    useEffect(() => {
        if (barcode) {
            dispatch(updateRegistrationValue({
                id: 'barcode',
                value: barcode
            }))
        }
    }, [barcode])
    
    const allFields = fields.reduce((prev, field) => {
        let output = [field.id]
        if (field.nestedQuestion) output.push(field?.nestedQuestion.id);
        if (field.subIds) {
            output = output.filter((o) => o !== field.id);
            output = [...output, ...field.subIds]
        }
        return [...prev, ...output]
    }, []);


    const allValues = useSelector((state) => 
        allFields.reduce((prev, key) => ({ ...prev, [key]: state.registration[key]}), {})
    )


    const requiredValues = useSelector((state) => 
        requiredFields.reduce((prev, key) => ({...prev, [key]: state.registration[key]}), {})
    );

    const submit = async () => {
        setLoading(true)
        setFieldError({});
        setOtherError(null);
        try {
            validateMissingValues();
            await furtherValidations();
        } catch (err) {
            console.log('error thrown', err)
            console.log(err.message)
            setLoading(false)
            return;
        }

        try {
            if (submitAction) await submitAction();
            if (nextRoute) navigate(nextRoute);
        } catch (err) {
            setOtherError(err.message)
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }

    const validateMissingValues = () => {
        let newMissingValues = []

        Object.entries(requiredValues).forEach(([key, value]) => {
            if (!value) newMissingValues.push(key)
        });

        setFieldError(
            newMissingValues.reduce((prev, key) => ({...prev, [key]: 'This field is required'}), {})
        )

        if (newMissingValues.length) {
            throw new Error(`There are some missing values: ${newMissingValues}`)
        }
    }

    const runValidation = async ([key, validation]) => {
        try {
            let value =  (key === 'other') ? allValues : allValues[key]
            await validation(value, allValues)
        } catch (err) {
            if (key === 'other') {
                setOtherError(err.message);
                throw new Error('error on other key');
            }
            setFieldError({
                [key]: err.message
            })
            throw new Error(`Error on key ${key}, ${err.message}`);
        }
    }

    const furtherValidations = async () => {
        if (!validations) return;
        validations = Object.entries(validations);
        for (let validation of validations) {
            await runValidation(validation)

        }
    }   

    return <>
        <KitHeader />
        <Wrapper>
            <RegistrationSectionTitle
            subtitle={subtitle}>
                {title}
            </RegistrationSectionTitle>
            {subtitleHtml}
            <Spacer height={32} />
            <RegistrationProgressBar percent={progress} />

            <Fields templateAreas={templateAreas}>
                {fields.map((field) => 
                <RegistrationInput 
                errors={fieldError}
                gridArea={field.id} 
                {...field} />)
                }
            </Fields>
            
            <Button loading={loading} onClick={submit} style={{ marginTop: 50 }}>{buttonCta}</Button>
            {otherError && <ErrorComponent>{otherError}</ErrorComponent>}
        </Wrapper>
    </>

}

