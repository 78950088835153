import React from 'react';
import { Modal } from './modal';
import styled from 'styled-components';
import { Column, Row } from './defaults';
import genetics from '../images/genetics.svg';
import check from '../images/check.svg';
import waiting from '../images/waiting.svg';
import moment from 'moment';
import { ProgressBar } from './progress-bar';

const Wrapper = styled(Column)`
    background: white;
    overflow: hidden;
    border-radius: 30px;
    padding: 16px;
    min-width: 650px;
    max-height: 738px;
    box-sizing: border-box;

    @media (max-width: 762px) {
        width: 100vw;
        height: 100vh;
        border-radius: 0px;
        padding: 20px;
        min-width: initial;
        min-height: initial;
        overflow: initial;
    }
`;

const TopWrapper = styled(Row)`
    background: #0A122A;
    padding: 24px;
    border-radius: 16px;
    margin-bottom:  38px;
`;

const IconWrapper = styled(Column)`
    align-items: center;
    justify-content: center;
    min-width: 82px;
    background: #4F59F0;
    border-radius: 8px;
`;

const Icon = styled.img`
    width: 25px;
`;

const TopTitle = styled.div`
    color: white;
    opacity: 0.7;
    font-size: 20px;
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-size: 23px;
    color: white;
    margin-bottom: 20px;
`;



const StatusRowWrapper = styled(Row)`
    padding-left: 38px;
    padding-right: 38px;
`;

const StatusRowIcon = styled.img`
    height: 30px;
`;

const StatusRowTitle = styled.div`
    margin-left: 20px;
    font-size: 16px;
    flex: 1;
    margin-top: 5px;
    color: ${props => props.done ? 'rgba(5, 31, 115, 1)' : 'In Progress'};
`;

const StatusRowDate = styled.div`
    color: ${props => props.status ===  'checked'? 
    'rgba(91, 99, 125, 1)' : props.status === 'ongoing' ? 
    'rgba(5, 31, 115, 1)' : 'rgba(5, 31, 115, 1)'};
    font-size: 16px;
    margin-top: 5px;
    font-family: 'Satoshi-Medium';
`;

const IconRow = styled(Column)`
    height: 62px;
    align-items: center;
`;

const RowLine = styled.div`
    width: 1px;
    background: rgba(79, 89, 240, 1);
    flex: 1;
    margin-bottom: 2px;
    margin-top: 2px;
`;

const DismissButton = styled.div`
    font-family: 'Satoshi-Bold';
    font-size: 17px;
    padding: 10px 15px;
    width: min-content;
    border-radius: 30px;
    border: 1px solid rgba(79, 89, 240, 1);
    color: rgba(79, 89, 240, 1);
    align-self: center;
    cursor: pointer;
`;

const StatusRow = ({ text, date, status, hideBar }) => {

    const checked = status === 'checked';
    const icon = checked ? check : waiting;
    
    let statusTxt = status === 'ongoing' ? 'In Progress' : 
    'Upcoming'

    return <StatusRowWrapper status={status} style={{ opacity: status === 'upcoming' ? 0.2 : 1}}>
        <IconRow>
            <StatusRowIcon src={icon} />
            {(!hideBar && status === 'checked') && <RowLine />}
        </IconRow>
        <StatusRowTitle status={status}>{text}</StatusRowTitle>
        <StatusRowDate status={status}>{date ? moment(date).format('mmm-dd') : statusTxt}</StatusRowDate>
    </StatusRowWrapper>

}

const statusFactory = (step, total = 5) => {
    return Array.from(Array(total)).map((_, i) => {
        if (step > i) return 'checked';
        if (step === i) return 'ongoing';
        if (step < i) return 'upcoming'
    })
}

export const TestStatus = ({ visible, setVisible, status}) => {

    if (!visible) return <div />

    const step = status === "PENDING" ? 0 :
        status === "UNASSIGNED" ? 0 :
        status === "REGISTERED" ? 0 : 
        status === "ON_LAB" ? 1 : 4

    const itemStatus = statusFactory(step);

    return <Modal onClickOutside={() => setVisible(false)}>
        <Wrapper>
            <TopWrapper>
                <IconWrapper>
                    <Icon src={genetics} />
                </IconWrapper>
                <Column style={{ flex: 1, marginLeft: 20 }}>
                    <TopTitle>Test Status</TopTitle>
                    <Title>We're sequencing your DNA...</Title>
                    <ProgressBar percent={step} total={5}/>
                </Column>
            </TopWrapper>
            <StatusRow status={itemStatus[0]} text='Kit Registration'  />
            <StatusRow status={itemStatus[1]} text='Received in Lab'  />
            <StatusRow status={itemStatus[2]} text='DNA Sequencing'  />
            <StatusRow status={itemStatus[3]}text='Sequence Analysis'  />
            <StatusRow hideBar status={itemStatus[4]} text='Review Results'  />
            <DismissButton onClick={() => setVisible(false)}>Dismiss</DismissButton>
        </Wrapper>
    </Modal>

}