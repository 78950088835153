import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`

`;

export const Arrow = ({ className, color }) => {
    return <Wrapper className={className}>
        <svg width="16" height="16" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.0476 1.53125L26.2714 11.755M26.2714 11.755L16.0476 21.9788M26.2714 11.755H1.73438" stroke={color} strokeWidth="2.49914" strokeLinecap="round" stroke-linejoin="round"/>
        </svg>
    </Wrapper>
}