import React from 'react';
import styled from 'styled-components';
import { Row } from './defaults';
import logoDark from '../images/logo-dark.svg';
import logoWhite from '../images/logo-white.svg';

const Logo = styled.img`
    height: 56px;

    @media (max-width: 768px) {
        height: 43px;
    }
`;

const Wrapper = styled(Row)`
    border-bottom: ${props => props.dark ? '1px solid #5963FD' : '1px solid #E0E0E0'};
    align-items: center;
    padding: 16px;
    background: ${props => props.dark ? '#4F59F0' : 'white'};

    @media (max-width: 768px) {
        padding-left: 15px;
        padding-right: 15px;
    }
`;

export const AuthHeader = ({ dark }) => {

    return <Wrapper dark={dark}>
        <Logo src={dark ? logoDark : logoWhite}/>
    </Wrapper>

}
