import React from 'react';
import styled from 'styled-components';
import bubble from '../images/bubble.svg';
import { Column } from './defaults';

const Date = styled.div`
    color: rgba(91, 99, 125, 1);
    font-size: 12px;
`;

const Value = styled.div`
    color: rgba(79, 89, 240, 1);
    font-size: 28px;
    margin-bottom: 0px;
`;

const Diff = styled.div`
    color: rgba(5, 31, 115, 1);
    font-size: 14px;
`;

const Wrapper = styled.img`
`;

const Container = styled(Column)`
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    align-items: center;
    padding-top: 13px;
`;

export const GraphTooltip = () => {

    return <div 
        id='graph-tooltip' 
        style={{ zIndex: 10, position: 'absolute', color: 'white', display: 'none' }}>
        <Wrapper src={bubble}/>
        <Container>
            <Date id='date' />
            <Value id='value' />
            <Diff id='diff' />
        </Container>
    </div>

    

}