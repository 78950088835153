import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  barcode: '',
  height_inches: '',
  height_feet: '',
  weight: '',
  supplement_consumption: '',
  coffee_consumption: '',
  alcohol_consumption: '',
  medicine_consumption: '',
  has_diabetes: '',
  has_cancer: '',
  overall_health: '',
  mental_health: '',
  stress: '',
  social_life: '',
  waking_up_condition: '',
  trouble_sleeping: '',
  sleep_hours: '',
  physical_activity_days: '',
  first_name: '',
  last_name: '',
  email: '',
  gender: '',
  biological_sex: '',
  date_of_birth: '',
  country: '',
  password: '',
  repeat_password: '',
  phone: '',
  ethnicity: '',
  supplements: '',
  medicine: '',
}

export const registrationSlice = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        clear: () => {
            return initialState
        },
        updateRegistrationValue: (state, action) => {
            state[action.payload.id] = action.payload.value
        }
    },
})

export const { clear, updateRegistrationValue } = registrationSlice.actions;

export default registrationSlice.reducer;
  