export async function downloadPDF(fileUrl) {
    try {
      const response = await fetch(fileUrl);
      fileUrl = fileUrl.split('/')[4]
      fileUrl = fileUrl.substring(0, fileUrl.indexOf('?'));
      fileUrl = `${fileUrl}.pdf`;
      fileUrl = decodeURIComponent(fileUrl)

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.setAttribute('download', fileUrl);
      link.click();
      link.remove();
  
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
}