import { Button, Column,Title } from './defaults';
import styled from 'styled-components';

export const MainBg = styled.img`
    position: absolute;
    top: 0px;
    z-index: 0;
    width: 100vw;
    min-height: 100%;

    @media (max-width: 762px) {
        height: 100vh;
    }
`;

export const Sphere = styled.img`
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100vw;
    min-height: 100%;


    @media (max-width: 762px) {
        height: 100vh;
    }
`;

export const FormContents = styled(Column)`
    z-index: 5;
    flex: 1;
`;

export const FormBox = styled(Column)`
    background: white;
    overflow: hidden;
    width: 500px;
    min-height: 450px;
    border-radius: 32px;
    padding: 40px;
    box-sizing: border-box;
    position: relative;
    z-index: 3;
    margin-top: 80px;
    margin-bottom: 100px;
    
    @media (max-width: 762px) {
        width: 90%;
        min-height: initial;
        border-radius: 20px;
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 90px;
        padding: 24px;
    }

    padding-top: 155px;

    @media (max-width: 530px) {
        padding-top: 128px;
    }

`;

export const Contents = styled(Column)`
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    align-items: center;
    min-width: 100vw;
`;

export const TopCurve = styled.img`
    position: absolute;
    top: 0px;
`;

export const ThisTitle = styled(Title)`
`;  

export const SubmitButton = styled(Button)`
    text-align: center;
    white-space: nowrap;
    line-break: none;

    @media (max-width: 530px) {
        align-self: stretch;
        width: initial;
    }
`;

export const ErrorComponent = styled.div`
    color: red;
    margin-top: 10px;
    margin-bottom: 20px;
`;
