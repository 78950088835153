import React from 'react';
import styled from 'styled-components';
import { Row } from './defaults';

const Wrapper = styled(Row)`
    height: 4px;
    width: 100px;
    background: #F1F3F7;
    border-radius: 4px;
    overflow: hidden;
`;

const Fill = styled.div`
    height: 100%;
    flex: ${props => props.flex};
    background: #F9BF29;
`;

const Empty = styled.div`
    height: 100%;
    flex: ${props => props.flex};
    background: transparent;
`;

export const ProgressBar = ({ percent = 50 }) => {

    return <Wrapper>
        <Fill flex={percent}/>
        <Empty flex={100-percent} />
    </Wrapper>


}

