import styled from "styled-components";
import Select from 'react-dropdown-select';
import loadingIcon from '../images/3-dots-move.svg';
import { forwardRef, useState } from "react";
import eyeButton from '../images/eye.svg';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './phone-number.css';


export const RED = '#FF374F';
export const YELLOW = '#FFCE1E';
export const GREEN = '#40F93C';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${props => props.breakpoint || '762px'}) {
    flex-direction: column;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const Title = styled.div`
  margin: 0px;
  font-size: 40px;
  color: #051F73;
  font-family: 'Satoshi-Bold';

  @media (max-width: 762px) {
    font-size: 25px;
    font-family: 'Satoshi-Bold';
  }
`;

const Label = styled.label`
  font-size: 16px;
  color: #5B637D;
  color: 'Satoshi-Medium';
  margin-bottom: 5px;
  ${props => props.error ? 'color: red' : ''};
`;

const InputBox = styled(Row)`
  height: 56px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #DBDBDB;
  border-radius: 8px;
  outline: none;
  font-family: 'Satoshi-Regular';
  overflow: hidden;
  position: relative;
  justify-content: flex-start;
`;

const InputArea = styled.input`
  font-size: 18px;
  outline: none;
  border: none;
  flex: 1;
  font-family: 'Satoshi-Medium';
  align-self: stretch;
  padding: 10px 15px;
  padding-right: ${props => props.isPasswordField ? '50px' : '15px'}
`;

const DropdownArea = styled(Select)`
  height: 56px;
  outline: none;
  align-items: center;
  justify-content: center;
  padding: 10px 15px !important;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #DBDBDB;
  font-size: 18px;
  border-radius: 8px !important;
  outline: none;
  font-family: 'Satoshi-Regular';

  * {
    font-size: 18px !important;
  }
`;

const LoadingIcon = styled.img`
  height: 25px;
  width: 25px;
  margin-left: 40px;
  margin-right: 40px;
`;

export const LinkTertiary = styled(Link)`
  margin-left: 30px;
  cursor: pointer;
  font-family: 'Satoshi-Bold';
  font-style: normal;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.013em;
  text-decoration: none;
  color: #4F59F0;
  white-space: nowrap;

  @media (max-width: 762px) {
    font-size: 17px;
  }
`;

const PrimaryButton = styled.div`
  border-radius: 28px;
  background: #4F59F0;
  color: white;
  font-family: 'Satoshi-Bold';
  padding: 13px 26px;
  box-sizing: border-box;
  width: min-content;
  font-size: 20px;
  justify-content: center;
  text-align-vertical: center;
  cursor: pointer;
  white-space: nowrap;
  pointer-events: ${props => props.disabled || props.loading ? 'none' : 'initial'};
  opacity: ${props => props.disabled || props.loading ? '0.5' : 'initial'};

  @media (max-width: 762px) {
    font-size: 17px;
  }
`;

export const Button = ({ className, loading, disabled, children, Component = PrimaryButton, ...props }) => {

  return <Component className={className} loading={loading} disabled={disabled} {...props}>
    {loading ? <LoadingIcon src={loadingIcon} /> : children}
  </Component>

}

const InputWrapper = styled(Column)`
  box-sizing: border-box;

  @media (max-width: 762px) {
    align-self: stretch;
  }
`;

const EyeButton = styled.img`
  opacity: ${props => props.active ? '1' : '0.3'};
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: 15px;
  top: 17px;
`;

export const Input = forwardRef(({ nextRef, submit, error, className, style, label, type, ...props}, ref) => {

  const [innerType, setInnerType] = useState(type);

  const onClickEye = () => {
    setInnerType((t) => t === 'password' ? 'text' : 'password')
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (nextRef) nextRef.current.focus();
      if (submit) submit()
    }
  }

  const onValueChanged = (e) => {
    if (type === 'date' && e.target?.value?.length === 10 && nextRef) {
      nextRef.current.focus()
    }
    else props?.onChange(e);
  }

  const onChangePhone = (v) => {
    props?.onChange({ target: { value: v }})
  }

  return <InputWrapper onChange={onValueChanged} className={className} style={style || {}}>
    {label && <Label error={error}>{label}</Label>}
    {type !== 'phone' ? <InputBox>
      <InputArea onKeyDown={onKeyDown} ref={ref} isPasswordField={type==='password'} type={innerType} error={error} {...props}/>
      {type === 'password' && <EyeButton active={innerType==='text'} onClick={onClickEye} src={eyeButton} alt='show password'/>}
    </InputBox> :  <PhoneInput 
        {...props}
        style={{ padding: 20 }}
        preferredCountries={['us']}
        onKeyDown={onKeyDown}
        country='us'
        inputProps={{
          ref
        }}
        containerClass="phoneInput"
        inputClass="phoneInputInput"
        buttonClass="phoneInputButton"
        onChange={onChangePhone} />}
  </InputWrapper>

})

export const Dropdown = ({ error, options, className, style, label, ...props}) => {

  options = options.map((o) => ({ label: o, value: o}));

  return <InputWrapper className={className} style={style || {}}>
    {label && <Label error={error}>{label}</Label>}
    <DropdownArea options={options} error={error} {...props}/>
  </InputWrapper>

}

export const Container = styled(Column)`
  min-height: 100vh;
`;